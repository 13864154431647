import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Home.css';
import Banner from './Banner';
import Footer from './Footer2';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';


disableReactDevTools();

class Home extends Component {

   constructor(props){
      super(props)
      this.state = {screen_size: "none",fontsize:"1.0vw","fontsize1":'30px',width:"58%",height:"9.5vw",offset:"offset-2"}
   }


   componentDidMount(){
      window.addEventListener("resize",this.resize.bind(this));
      this.resize();
   }

   resize(){
      this.setState({width: window.innerWidth, height: window.innerHeight});
      
      if (window.innerWidth < 576){
          console.log("X-Small")
          this.setState({screen_size: "X-Small",fontsize: "2.0vw", fontsize1: "20px",width: "50%", offset: "offset-1",height: "3.5vw"})

      } else if (window.innerWidth > 576 && window.innerWidth < 768){
          console.log("Small")
          this.setState({screen_size: "Small",fontsize: "1.8vw",fontsize1: "22px" ,width:"55%", offset: "offset-1", height: "5.5vw"})

      } else if (window.innerWidth > 768 && window.innerWidth < 992){
          console.log("Medium")
          this.setState({screen_size: "Medium",fontsize: "1.6vw",fontsize1:"24px" ,width:"58%",offset: "offset-2", height: "9.5vw"})

      } else if (window.innerWidth > 992 && window.innerWidth < 1200){
          console.log("Large")
          this.setState({screen_size: "Large",fontsize: "1.4vw",fontsize1: "26px",width:"65%"})

      } else if (window.innerWidth > 1200 && window.innerWidth < 1400){
          console.log("X-Large")
          this.setState({screen_size: "X-Large",fontsize: "1.2vw",fontsize1: "28px" ,width:"58%"})

      } else if (window.innerWidth > 1400){
          console.log("XX-Large")
          this.setState({screen_size: "XX-Large",fontsize: "1.0vw",fontsize1: "30px", width:"58%"})
      }
   }

   render(){
    return (
       <Container fluid className="container_landing">
       <Banner />

       <Row className="row_middle">

       <Col className={this.state.offset}>
         <Row> 
            <div style={{height: `${this.state.height}`}}>
            </div>
         </Row>
         <Row> 
            {/*{this.state.screen_size}*/}
            <div className="pt-4 ms-4 text-wrap me-4" style={{color: "white",fontFamily: "'Lato', sans-serif", fontWeight: '0',fontSize: `${this.state.fontsize1}`, width: "90%"}}>
            Evolved Cyber Security Training
            </div>
         </Row>
         <Row>
            <div className="ms-4 mt-4 text-wrap me-0" style={{color: "white", fontSize: 'calc(px+1vw)',width: "90%"}}>
               <p className="paragraph1" style={{lineHeight: "2", fontSize: `${this.state.fontsize}`, fontFamily: "'Lato', sans-serif", fontWeight: '300', width: `${this.state.width}`}}>
               Welcome to Cyber Security Skills, where we offer a truly unique approach to cyber security training. Our expert instructors bring real-world experience and cutting-edge techniques to the classroom, providing a dynamic and hands-on learning experience unlike any other.
               </p>
            </div>
         </Row>
         <Row className="ms-4">
            <a href="/training">
            <div className="courses_button p-2" style={{background: "#0672d6", width: 'fit-content', borderRadius: "30px",color:"white",fontFamily: "'Abel', sans-serif", fontWeight: '500',fontSize: 'calc(16px + 0.3vw)'}}>
            <div style={{margin:'auto',width: 'fit-content', fontSize: `${this.state.fontsize}`, paddingRight: '20px', paddingLeft: '20px'}}>
            Courses
            </div> 
            </div>
            </a>
         </Row>
         <Row> 
            <div style={{height: "10.5vw"}}>
            </div>
         </Row>
       </Col>
   
       <Col className="col-2 d-flex d-none d-xs-block d-sm-block d-md-block d-lg-block d-xl-none">
       </Col>

       </Row>

       <Footer />
       </Container>
     )
  }
}

export default Home;
