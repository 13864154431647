import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Footer.css';
import React, { Component } from "react";

class Footer extends Component {

  constructor(props){
     super(props)
     this.state = {screen_size: "none",display:"block",fontsize:"20px"}

  }

   componentDidMount(){
      window.addEventListener("resize",this.resize.bind(this));
      this.resize();
   }


   resize(){
      this.setState({width: window.innerWidth, height: window.innerHeight});

      if (window.innerWidth < 576){
          //console.log("X-Small")
          this.setState({screen_size: "X-Small", display:"none",fontsize:"10px"})

      } else if (window.innerWidth > 576 && window.innerWidth < 768){
          //console.log("Small")
          this.setState({screen_size: "Small", display:"none",fontsize:"12px"})

      } else if (window.innerWidth > 768 && window.innerWidth < 992){
          //console.log("Medium")
          this.setState({screen_size: "Medium",display:"inline",fontsize:"14px"})

      } else if (window.innerWidth > 992 && window.innerWidth < 1200){
          //console.log("Large")
          this.setState({screen_size: "Large",display:"inline",fontsize:"16px"})

      } else if (window.innerWidth > 1200 && window.innerWidth < 1400){
          //console.log("X-Large")
          this.setState({screen_size: "X-Large",display:"inline",fontsize:"18px"})

      } else if (window.innerWidth > 1400){
          //console.log("XX-Large")
          this.setState({screen_size: "XX-Large",display:"inline",fontsize:"18px"})
      }
   }

  render(){

     var { Fixed: Fixed } = this.props

     // Here we add the Fixed variable to the className.  This allows us to call the Banner component and set it to fixed-top
     var CustClassName = "footer" + Fixed

     return (
        <Row className={CustClassName} style={{height: "auto",color: "#0a0a0a", fontSize: `${this.state.fontsize}`}}>
           <Row style={{height:"2vw"}} />
           <Row style={{color:"white", height: "110px"}}>
              <Col />

              <Col className="col-1" style={{background: "none",width:"auto"}}>

                 <Row className="d-flex justify-content-center pb-4">
                    Contact us: 
                 </Row>
                 <Row className="pb-2"/>

                 {(this.state.screen_size !== "Small" && this.state.screen_size != "X-Small") &&

                    <Row className="pb-1" style={{background:"none"}}>

                       <Col className="p-0">
                          <a href="mailto:training@cybersecurityskills.net"><div className="email_logo" style={{height:"20px",width:"25px"}}></div></a>
                       </Col>

                       <Col>
                          <a className="text-decoration-none" href="mailto:training@cybersecurityskills.net">Email</a>
                       </Col>

                       <Col/>
   
                       <Col className="p-0">
                          <a href="https://twitter.com/CyberSecur70715"><div className="twitter_logo" style={{height:"20px",width:"25px"}}></div></a>
                       </Col>
                       <Col>
                          <a className="text-decoration-none" href="https://twitter.com/CyberSecur70715">Twitter/X</a>
                       </Col>

                       <Col/>
      
                       <Col className="p-0">
                          <a href="https://t.me/AuditronBot"><div className="tg_logo" style={{height:"20px",width:"25px"}}></div></a>
                       </Col>
                       <Col>
                          <a className="text-decoration-none" href="https://t.me/AuditronBot">Telegram</a>
                       </Col>
                    </Row>

                 }

                 {(this.state.screen_size === "Small" || this.state.screen_size == "X-Small") &&
                
                    <Col>
                       <Row className="pb-2">

                          <Col className="email_logo" style={{width: "100px"}}>
                          </Col>
                          <Col>
                             Email    
                          </Col>
   
                       </Row>

                       <Row className="pb-2">

                          <Col className="twitter_logo" style={{width: "100px"}}>
                          </Col>
                          <Col>
                             Twitter/X    
                          </Col>
      
                       </Row>
              
                       <Row className="pb-2">

                          <Col className="tg_logo" style={{width: "100px"}}>
                          </Col>
                          <Col>
                             Telegram    
                          </Col>

                       </Row>
                    </Col>
                 }
              </Col>
              <Col className="last_col"/>
           </Row>

           {(this.state.screen_size !== "Small" && this.state.screen_size !== "X-Small")&&
            <Row style={{color:"white",background:"none"}}>
              <Col className="d-flex justify-content-center">
                 &copy; 2017 CyberSecuritySkills.net 
              </Col>
            </Row>
           }

           {(this.state.screen_size === "Small" || this.state.screen_size === "X-Small") &&
            <Row className="pt-5" style={{color:"white"}}>
              <Row style={{height:"10px"}}/>
              <Col className="d-flex justify-content-center pb-3">
                 &copy; 2017 CyberSecuritySkills.net 
              </Col>
            </Row>
           }
       </Row>
     );


   }
}

export default Footer;
