import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Certification.css';
import Banner from './Banner'
import Footer from './Footer2'


class Certification extends Component {
  render(){
  return (
    <Container fluid className="container_landing" style={{ height: window.innerHeight}}>
    <Banner />

    <Row style={{}} className="row_middle_certification" style={{height: "200%"}}>
       <Col style={{}} className="col-1"></Col>
       <Col style={{}} className="col-10" style={{}}>

       </Col>

       <Col className="col-1"></Col>

    </Row>


    <Footer />

    </Container>

  )

  }
}

export default Certification; 
