import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Assessment.css';
import Banner from './Banner'
import Footer from './Footer2'
import { disableReactDevTools } from '@fvilers/disable-react-devtools';


disableReactDevTools();

class Assessment extends Component {

   constructor(props){
      super(props)
      this.state = {screen_size: "none",fontSize:"20px",fontsize1:"18px",logo_width:"80px",assessment_code:""}

   }

   componentDidMount(){
      window.addEventListener("resize",this.resize.bind(this));
      this.resize();
   }

   resize(){
      this.setState({width: window.innerWidth, height: window.innerHeight});

      if (window.innerWidth < 576){
          console.log("X-Small "+window.innerWidth)
          this.setState({screen_size: "X-Small",fontsize: "12px",fontsize1:"12px"})

      } else if (window.innerWidth > 576 && window.innerWidth < 768){
          console.log("Small "+window.innerWidth)
          this.setState({screen_size: "Small",fontsize: "14px",fontsize1:"12px"})

      } else if (window.innerWidth > 768 && window.innerWidth < 992){
          console.log("Medium")
          this.setState({screen_size: "Medium",fontsize: "16px",fontsize1:"14px"})

      } else if (window.innerWidth > 992 && window.innerWidth < 1200){
          console.log("Large")
          this.setState({screen_size: "Large",fontsize: "18px",fontsize1:"14px"})

      } else if (window.innerWidth > 1200 && window.innerWidth < 1400){
          console.log("X-Large")
          this.setState({screen_size: "X-Large",fontsize: "20px",fontsize1:"16px"})

      } else if (window.innerWidth > 1400){
          console.log("XX-Large")
          this.setState({screen_size: "XX-Large",fontsize: "22px",fontsize1:"18px"})
      } else {
          this.setState({screen_size: "No Match"})
      }

   }




  handleCodeSubmit(event){
     console.log(event.target.value);
     //this.setState({assessment_code: event.target.value});
  }

  handleClick(event){
     console.log("Click "+event);  
  }


  render(){

     var assessment_code = "" 
     var message = ""

  return (
    <Container fluid className="container_landing" style={{ height: window.innerHeight}}>

    <Banner Fixed="fixed-top"/>

    <Row style={{}} className="row_middle_assess" style={{height: "auto"}}>
    {/*<Col style={{}} className="col-2" style={{background:"none"}}></Col>*/}
    <Col style={{}} className="col-2 d-flex d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" />


    {/*<Col style={{}} className="col-8 ps-5 pe-5" style={{background:"none"}}>*/}
    <Col style={{}} className="ps-5 pe-5" style={{background:"none"}}>

       <Row style={{}} className="row_middle_assess1" style={{height: "100px"}} />
       <Row style={{}} className="row_middle_assess1" style={{height: "auto"}}>

          <Col style={{}} style = {{background:"none"}}>

             <Row className="d-flex d-md-flex p-4" style={{background: "rgba(56,60,63,1.0)",borderRadius: "20px",height: "auto",boxShadow: "10px 10px 20px black"}}>

                 <Col className="ps-5 pt-2 col-6" style={{color: "white",width: "auto"}}>
                    <Row style={{color: "white",fontFamily: "'Lato', sans-serif", fontWeight: '0',fontSize: `${this.state.fontsize}`, width: "90%"}}>Take an assessment</Row>
                    <Row className="" style={{height: "20px"}} />
                    <Row>
                       <div style={{fontSize: `${this.state.fontsize1}`}}>
                       Once you have completed your course you will receive an assessment code.   When you are ready to take the assessment enter the code in the box below to start your assessment.   Please ensure you are ready to take the assessment before entering the code below
                       </div>
                    </Row>
                    <Row className="" style={{}} style={{height: "60px"}} />
                    <Row className="p-4 ps-5 pe-5" style={{background: ""}}>
                       <Col className="col-4 d-flex d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
                       </Col>
                       {/*<Col className="col-4" style={{background:"none", fontSize: `${this.state.fontsize}`}}>*/}
                       <Col className="" style={{background:"none", fontSize: `${this.state.fontsize}`}}>
                             <Row className="d-flex justify-content-start pe-4 pb-2" style={{background: "none", fontSize: `${this.state.fontsize1}`}}>Assessment Code:</Row>
                 
                             <Row className="ps-0 pb-4">
                                 <input 
                                   style={{borderWidth: "0px", borderRadius: "4px", background: "black", color: "white"}} 
                                   type="text" 
                                   id="assessment_code" 
                                   name="assessment_code"  
                                   onChange={this.handleCodeSubmit} 
                                   value={this.assessment_code} />
                             </Row> 
                             
                             <Row className="d-flex justify-content-end">
                                <button 
                                  className="" 
                                  onClick={this.handleClick} 
                                  style={{background: "#0672d6", width: 'fit-content', borderWidth: "0px", borderRadius: "30px",color:"white",fontFamily: "'Abel', sans-serif", fontWeight: '500',fontSize: `${this.state.fontsize1 - 10}`}}>Start Assessment
                               </button>
                             </Row>

                       </Col>
                       <Col className="col-4 d-flex d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" />
                    </Row>
                 </Col>


             </Row> 

          </Col>

       </Row>
       <Row style={{}} className="row_middle_assess1" style={{height: "100px"}} />
    
    </Col>

    {/*<Col className="col-1"></Col>*/}
    <Col style={{}} className="col-1 d-flex d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" />

    </Row>


    <Footer />


    </Container>

  )

  }
}

export default Assessment; 
