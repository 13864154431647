import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Consulting.css';
import Banner from './Banner'
import Footer from './Footer2'
import { disableReactDevTools } from '@fvilers/disable-react-devtools';


disableReactDevTools();

class Consulting extends Component {

constructor(props){
      super(props)
      this.state = {screen_size: "none",fontsize:"1.0vw","fontsize1":'30px',width:"58%",height:"9.5vw",offset:"offset-2", height_bg: "auto"}
   }


   componentDidMount(){
      window.addEventListener("resize",this.resize.bind(this));
      this.resize();
   }

   resize(){
      this.setState({width: window.innerWidth, height: window.innerHeight});

      if (window.innerWidth < 576){
          console.log("X-Small")
          this.setState({screen_size: "X-Small",fontsize: "2.0vw", fontsize1: "20px",width: "47%", offset: "offset-1",height: "3.5vw"})

      } else if (window.innerWidth > 576 && window.innerWidth < 768){
          console.log("Small")
          /*{this.setState({screen_size: "Small",fontsize: "1.8vw",fontsize1: "22px" ,width:"52%", offset: "offset-1", height: "5.5vw"})}*/
          this.setState({screen_size: "Small",fontsize: "1.2vw",fontsize1: "22px" ,width:"52%", offset: "offset-1", height: "5.5vw"})

      } else if (window.innerWidth > 768 && window.innerWidth < 992){
          console.log("Medium")
          /*this.setState({screen_size: "Medium",fontsize: "1.6vw",fontsize1:"24px" ,width:"55%",offset: "offset-2", height: "9.5vw"})*/
          this.setState({screen_size: "Medium",fontsize: "1.2vw",fontsize1:"24px" ,width:"55%",offset: "offset-2", height: "9.5vw"})

      } else if (window.innerWidth > 992 && window.innerWidth < 1200){
          console.log("Large")
          /*this.setState({screen_size: "Large",fontsize: "1.4vw",fontsize1: "26px",width:"62%"})*/
          this.setState({screen_size: "Large",fontsize: "1.2vw",fontsize1: "26px" ,width:"62%"})

      } else if (window.innerWidth > 1200 && window.innerWidth < 1400){
          console.log("X-Large")
          /*this.setState({screen_size: "X-Large",fontsize: "1.2vw",fontsize1: "28px" ,width:"55%"})*/
          this.setState({screen_size: "X-Large",fontsize: "1.0vw",fontsize1: "28px" ,width:"55%"})

      } else if (window.innerWidth > 1400){
          console.log("XX-Large")
          this.setState({screen_size: "XX-Large",fontsize: "1.0vw",fontsize1: "30px", width:"55%"})
      }
   }

  render(){
  return (

       <Container fluid className="container_landing">
       <Banner />

       <Row className="row_middle_consulting">

       <Col className={this.state.offset} style={{background: "none"}}>
         <Row>
            <div style={{height: `${this.state.height}`}}>
            </div>
         </Row>
         <Row>
            <div className="ms-4 text-wrap me-4" style={{color: "white",fontFamily: "'Lato', sans-serif", fontWeight: '0',fontSize: `${this.state.fontsize1}`, width: "80%"}}>
            Connecting talent and opportunity
            </div>
         </Row>
         <Row>
            {/*<div className="ms-4 mt-4 text-wrap" style={{color: "white", fontSize: 'calc(px+1vw)',width: "90%"}}>*/}
            <div className="ms-4 mt-4 text-wrap" style={{color: "white", fontSize: `${this.state.fontsize}`, width: "90%"}}>
               <p className="paragraph1" style={{lineHeight: "2", fontSize: `${this.state.fontsize}`, fontFamily: "'Lato', sans-serif", fontWeight: '300', width: `${this.state.width}`}}>
               At CyberSecuritySkills.net we train and interact with numerous highly motivated and talented resources from across the globe.   It has therefore been a logical progression for our business to provide a service which connects these talented individuals with companies needing skills.
               </p>
               <p className="paragraph1" style={{lineHeight: "2", fontSize: `${this.state.fontsize}`, fontFamily: "'Lato', sans-serif", fontWeight: '300', width: `${this.state.width}`}}>
               With skills spanning a wide variety of areas in both Cybersecurity and general IT our resources will be able to assist you with your next project.  All active consultants in our database have completed skills assessments and have had their prior experience verified. 
               </p>
            </div>
         </Row>
         <Row>
            <div style={{background: "none" ,height: "10.5vw"}}>
            </div>
         </Row>
       </Col>

       <Col className="col-2 d-flex d-none d-xs-block d-sm-block d-md-block d-lg-block d-xl-none">
       </Col>

       </Row>

       <Footer />
       </Container>


  )

  }
}

export default Consulting; 
