import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home"; 
import Training from "./Training";
import Assessment from "./Assessment";
import Certification from "./Certification";
import Consulting from "./Consulting";

export default function App(){
   return(
     <BrowserRouter>
        <Routes>
           <Route index element={<Home />} />
           <Route path="home" element={<Home />} />
           <Route path="training" element={<Training />} />
           <Route path="assessment" element={<Assessment />} />
           <Route path="certification" element={<Certification />} />
           <Route path="consulting" element={<Consulting />} />
        </Routes>
     </BrowserRouter>
   );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
