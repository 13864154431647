// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("imgs/website_background_empty.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n   --background_blue: rgba(6,6,69,0.9); \n   --background_img_bot: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\n}\n\n\n.row_middle_assess {\n   background-color: #0a0a0a;\n   background-image: var(--background_img_bot);\n   background-repeat:  no-repeat, no-repeat;\n   background-size: 100% 100%;\n   background-attachment: fixed;\n   //height: 200%;\n\n}\n\n\n.row_bottom {\n   background-color: #0a0a0a;\n   height: 20%;\n}\n\n@media only screen and (max-width: 1200px) {\n  .row_top {\n     height: 242px;\n  }\n}\n\n", "",{"version":3,"sources":["webpack://./src/Assessment.css"],"names":[],"mappings":"AAAA;GACG,mCAAmC;GACnC,6DAA4D;;AAE/D;;;AAGA;GACG,yBAAyB;GACzB,2CAA2C;GAC3C,wCAAwC;GACxC,0BAA0B;GAC1B,4BAA4B;GAC5B,cAAc;;AAEjB;;;AAGA;GACG,yBAAyB;GACzB,WAAW;AACd;;AAEA;EACE;KACG,aAAa;EAChB;AACF","sourcesContent":[":root {\n   --background_blue: rgba(6,6,69,0.9); \n   --background_img_bot: url(imgs/website_background_empty.png);\n\n}\n\n\n.row_middle_assess {\n   background-color: #0a0a0a;\n   background-image: var(--background_img_bot);\n   background-repeat:  no-repeat, no-repeat;\n   background-size: 100% 100%;\n   background-attachment: fixed;\n   //height: 200%;\n\n}\n\n\n.row_bottom {\n   background-color: #0a0a0a;\n   height: 20%;\n}\n\n@media only screen and (max-width: 1200px) {\n  .row_top {\n     height: 242px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
