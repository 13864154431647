import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Training.css';
import Banner from './Banner'
import Footer from './Footer2'
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

disableReactDevTools();

class TrainingCard extends Component {

   constructor(props){
      super(props)
      this.state = {screen_size: "none",fontSize:"20px",fontsize1:"18px",logo_width:"80px"}
      
   }

   componentDidMount(){
      window.addEventListener("resize",this.resize.bind(this));
      this.resize();
   }

   resize(){
      this.setState({width: window.innerWidth, height: window.innerHeight});

      if (window.innerWidth < 576){
          console.log("X-Small "+window.innerWidth)
          this.setState({screen_size: "X-Small",fontsize: "12px",fontsize1:"12px",logo_width:"50px"})

      } else if (window.innerWidth > 576 && window.innerWidth < 768){
          console.log("Small "+window.innerWidth)
          this.setState({screen_size: "Small",fontsize: "14px",fontsize1:"12px",logo_width:"70px"})

      } else if (window.innerWidth > 768 && window.innerWidth < 992){
          console.log("Medium")
          this.setState({screen_size: "Medium",fontsize: "16px",fontsize1:"14px",logo_width:"70px"})

      } else if (window.innerWidth > 992 && window.innerWidth < 1200){
          console.log("Large")
          this.setState({screen_size: "Large",fontsize: "18px",fontsize1:"14px",logo_width:"70px"})

      } else if (window.innerWidth > 1200 && window.innerWidth < 1400){
          console.log("X-Large")
          this.setState({screen_size: "X-Large",fontsize: "20px",fontsize1:"16px",logo_width:"80px"})

      } else if (window.innerWidth > 1400){
          console.log("XX-Large")
          this.setState({screen_size: "XX-Large",fontsize: "22px",fontsize1:"18px",logo_width:"80px"})
      } else {
          this.setState({screen_size: "No Match"})
      }
      
   }


   render(){

      var { Logo: Logo, SpacingTop: SpacingTop, SpacingBottom: SpacingBottom, Title: Title, Desc: Desc, Price: Price, Currency: Currency, Status: Status,DisplayEnrolButton: DisplayEnrolButton,Url: Url} = this.props 

      return (

      <div>
      {/* Course card starts here */}
      <Row> 
         {/* Spacing between cards is set with maxHeight below */}
         <div style={{minHeight: SpacingTop ,maxHeight: "300px",background:"none"}}>
         </div>
      </Row>


      <Row className="d-flex d-md-flex" style={{background: "rgba(56,60,63,1.0)",borderRadius: "20px",height: "auto",boxShadow: "10px 10px 20px black"}}>

           <Col className={`col-2 ${Logo} ps-3 pt-2`} style={{minWidth: `${this.state.logo_width}`, maxWidth:`${this.state.logo_width}`,padding: "10px"}}>
           </Col>

           <Col className="" style={{}}>

              <Row className="" style={{}}>
                 <Col className="text-nowrap course_title ps-2 pt-2 col-6" style={{color: "white",fontSize: `${this.state.fontsize}`}}>
                 {Title} 
                 </Col>
                 <Col className="col-6 pt-3 pe-4" style={{color: "white", fontSize: `${this.state.fontsize}`, textAlign: "right"}}>
                 Price: {Currency}{Price}
                 </Col>
              </Row>

              <Row className="ps-2 pt-4 pe-5 pb-4" style={{color: "white",fontSize: `${this.state.fontsize1}`}}>
                 {Desc}
              </Row>

              <Row className="ps-0 pt-4 pe-4 pb-4" style={{color: "white", fontSize: `${this.state.fontsize}`}}>
                 <Col className="col-6" style={{background: "none"}}>
                    <a href={Url}>
                    <div className="courses_button p-1" style={{display:`${DisplayEnrolButton}`,background: "#0672d6",width:"110px",borderRadius: "30px",color:"white",fontFamily: "'Abel', sans-serif", fontWeight: '500',fontSize: '20px'}}>
                        <div style={{margin:'auto',width: 'fit-content',paddingLeft: '10px',paddingRight: '10px'}}>
                           Enrol 
                        </div>
                    </div>
                    </a>
                 </Col>
                 <Col className="col-6" style={{textAlign: "right"}}>
                    Status: {Status}
                 </Col>
              </Row>

           </Col>


 
      </Row>

      {/* Course card stops here */}

      <Row> 
         <div style={{minHeight: SpacingBottom ,maxHeight: "300px"}}>
         </div>
      </Row>


      </div>
      )
   }
}

class TrainingCardList extends Component {

   constructor(props){
      super(props)
      this.state = {screen_size: "none",fontSize:"20px",fontsize1:"18px",logo_width:"80px"}
      
   }

   componentDidMount(){
      window.addEventListener("resize",this.resize.bind(this));
      this.resize();
   }

   resize(){
      this.setState({width: window.innerWidth, height: window.innerHeight});

      if (window.innerWidth < 576){
          console.log("X-Small "+window.innerWidth)
          this.setState({screen_size: "X-Small",fontsize: "12px",fontsize1:"12px",logo_width:"50px"})

      } else if (window.innerWidth > 576 && window.innerWidth < 768){
          console.log("Small "+window.innerWidth)
          this.setState({screen_size: "Small",fontsize: "14px",fontsize1:"12px",logo_width:"70px"})

      } else if (window.innerWidth > 768 && window.innerWidth < 992){
          console.log("Medium")
          this.setState({screen_size: "Medium",fontsize: "16px",fontsize1:"14px",logo_width:"70px"})

      } else if (window.innerWidth > 992 && window.innerWidth < 1200){
          console.log("Large")
          this.setState({screen_size: "Large",fontsize: "18px",fontsize1:"14px",logo_width:"70px"})

      } else if (window.innerWidth > 1200 && window.innerWidth < 1400){
          console.log("X-Large")
          this.setState({screen_size: "X-Large",fontsize: "20px",fontsize1:"16px",logo_width:"80px"})

      } else if (window.innerWidth > 1400){
          console.log("XX-Large")
          this.setState({screen_size: "XX-Large",fontsize: "22px",fontsize1:"18px",logo_width:"80px"})
      } else {
          this.setState({screen_size: "No Match"})
      }
      
   }


   render(){

      var { Logo: Logo, SpacingTop: SpacingTop, SpacingBottom: SpacingBottom, Title: Title, Desc: Desc, List: List, Price: Price, Currency: Currency, Status: Status,DisplayEnrolButton: DisplayEnrolButton,Url: Url} = this.props 

      const listItems = List.map((items) => <li>{items}</li> );
      

      return (

      <div>
      {/* Course card starts here */}
      <Row> 
         {/* Spacing between cards is set with maxHeight below */}
         <div style={{minHeight: SpacingTop ,maxHeight: "300px",background:"none"}}>
         </div>
      </Row>


      <Row className="d-flex d-md-flex" style={{background: "rgba(56,60,63,1.0)",borderRadius: "20px",height: "auto",boxShadow: "10px 10px 20px black"}}>

           <Col className={`col-2 ${Logo} ps-3 pt-2`} style={{minWidth: `${this.state.logo_width}`, maxWidth:`${this.state.logo_width}`,padding: "10px"}}>
           </Col>

           <Col className="" style={{}}>

              <Row className="" style={{}}>
                 <Col className="text-nowrap course_title ps-2 pt-2 col-6" style={{color: "white",fontSize: `${this.state.fontsize}`}}>
                 {Title} 
                 </Col>
                 <Col className="col-6 pt-3 pe-4" style={{color: "white", fontSize: `${this.state.fontsize}`, textAlign: "right"}}>
                 Price: {Currency}{Price}
                 </Col>
              </Row>

              <Row className="ps-2 pt-4 pe-5 pb-4" style={{color: "white",fontSize: `${this.state.fontsize1}`}}>
                 {Desc}
                 <div style={{height: "20px"}}>&nbsp;</div> 
                 <div className="list" style={{left: "10px"}}>
                    <ul>{listItems}</ul>
                 </div>
              </Row>

              <Row className="ps-0 pt-4 pe-4 pb-4" style={{color: "white", fontSize: `${this.state.fontsize}`}}>
                 <Col className="col-6" style={{background: "none"}}>
                    <a href={Url}>
                    <div className="courses_button p-1" style={{display:`${DisplayEnrolButton}`,background: "#0672d6",width:"110px",borderRadius: "30px",color:"white",fontFamily: "'Abel', sans-serif", fontWeight: '500',fontSize: '20px'}}>
                        <div style={{margin:'auto',width: 'fit-content',paddingLeft: '10px',paddingRight: '10px'}}>
                           Enrol 
                        </div>
                    </div>
                    </a>
                 </Col>
                 <Col className="col-6" style={{textAlign: "right"}}>
                    Status: {Status}
                 </Col>
              </Row>

           </Col>


 
      </Row>

      {/* Course card stops here */}

      <Row> 
         <div style={{minHeight: SpacingBottom ,maxHeight: "300px"}}>
         </div>
      </Row>


      </div>
      )
   }
}


class Training extends Component {

   constructor(props){
      super(props)
      this.state = {screen_size: "none"}

   }


   componentDidMount(){
      window.addEventListener("resize",this.resize.bind(this));
      this.resize();
   }

   resize(){
      this.setState({width: window.innerWidth, height: window.innerHeight});

      if (window.innerWidth < 576){
          console.log("X-Small")
          this.setState({screen_size: "X-Small"})

      } else if (window.innerWidth > 576 && window.innerWidth < 768){
          console.log("Small")
          this.setState({screen_size: "Small"})

      } else if (window.innerWidth > 768 && window.innerWidth < 992){
          console.log("Medium")
          this.setState({screen_size: "Medium"})

      } else if (window.innerWidth > 992 && window.innerWidth < 1200){
          console.log("Large")
          this.setState({screen_size: "Large"})

      } else if (window.innerWidth > 1200 && window.innerWidth < 1400){
          console.log("X-Large")
          this.setState({screen_size: "X-Large"})

      } else if (window.innerWidth > 1400){
          console.log("XX-Large")
          this.setState({screen_size: "XX-Large"})
      }
   }

  render(){
  return (
    <Container fluid className="container_landing" style={{ height: window.innerHeight}}>
    <Banner Fixed="fixed-top"/>

    <Row style={{}} className="row_middle_train" style={{height: "auto"}}>

    {/*left most col*/}
    {/*<Col className="col-2" style={{background:"red"}}></Col>*/}
    <Col style={{}} className="col-2 d-flex d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" />

    
    {/*<Col style={{}} className="col-8 ps-5 pe-5" style={{background:"none"}}>*/}
    <Col style={{}} className="ps-5 pe-5" style={{background:"none"}}>
       {/*{this.state.screen_size}*/}
      {/*<TrainingCard Logo="suricata_101_course_logo" SpacingTop="100px" Title="Suricata 101" Desc="This course is designed to provide students with the knowledge and skills necessary to successfully install and configure Suricata IDPS.&nbsp;&nbsp;Suricata is a powerful open-source intrusion detection and prevention system that can detect and block a wide range of threats, including malware, exploits, and other types of attacks. This course covers the essential concepts of IDS/IPS, and then focuses on the practical aspects of deploying Suricata in various environments." Currency="$" Price="500" Status="Live" Url="https://training3.cybersecurityskills.net/course/view.php?id=2"/>*/}

      <TrainingCardList Logo="suricata_101_course_logo" SpacingTop="100px" Title="Suricata 101" Desc="This course is designed to provide students with the knowledge and skills necessary to successfully install and configure Suricata IDPS.&nbsp;&nbsp;Suricata is a powerful open-source intrusion detection and prevention system that can detect and block a wide range of threats, including malware, exploits, and other types of attacks.&nbsp;&nbsp;This course covers the following topics:" List={["IDPS introduction and basics","Suricata basics","Rule basics","Run-modes and capture-modes","Building a lab environment using VirtualBox and GNS3 for Windows and Linux hosts","Practical lab exercises covering AF-PACKET, NFQUEUE and PCAP capture-modes"]} Currency="$" Price="499" Status="Coming Soon" Url="https://training3.cybersecurityskills.net/course/view.php?id=2" DisplayEnrolButton="none"/>

      <TrainingCard Logo="wazuh_101_course_logo" SpacingTop="100px" Title="Wazuh 101" Desc="Learn to build a Secure Operations Center environment using Wazuh. This course teaches students how to deploy Wazuh SIEM and XDR.  You will learn how to manage and customize security events being generated by end-points, as well as how to manage the Wazuh backend components to customize how and when alerts are generated.  Need more CyberSecurity insights into your environment?  This is the course for you" Currency="$" Price="TBD" Status="Coming soon" DisplayEnrolButton="none" />

      <TrainingCard Logo="gns3_101_course_logo" SpacingTop="100px" Title="GNS3 101" Desc="GNS3 is one of the most powerful graphical network simulators available with support for VirtualBox and QEMU guests.   Whether you need to verify technical designs through simulation or build Honeypots to trap attackers GNS3, Virtualbox and QEMU can do it all.  This course will teach you how to get up and running with GNS3, VirtualBox and QEMU, giving you limitless simulation and learning capabilities.  Don't wonder if a design will work, simulate the design and know it will work before implementation" Currency="$" Price="TBD" Status="Coming soon" DisplayEnrolButton="none"/>


      <TrainingCardList Logo="suricata_201_course_logo" SpacingTop="100px" Title="Suricata 201" Desc="Building on Suricata 101, you will learn the following:" List={["Advanced features and how to use them in your rules","How to track attackers using JA3 signatures","How to write signatures to detect unauthorized access to services such as SSH and RDP","Vizualize Suricata alerts using FileBeat, ElasticSearch and Grafana"]} Currency="$" Price="TBD" Status="Coming soon" DisplayEnrolButton="none" />

      <TrainingCard Logo="suricata_301_course_logo" SpacingTop="100px" SpacingBottom="100px" Title="Suricata 301" Desc="Building further on our previous Suricata courses, we will focus on high performance deployments, taking Suricata to 10Gbps and beyond." Currency="$" Price="TBD" Status="Coming soon" DisplayEnrolButton="none" />



    </Col>

    {/*right most col*/}
    {/*<Col style={{background: "green"}} className="col-1"></Col>*/}
    <Col style={{}} className="col-2 d-flex d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" />

    </Row>


    <Footer />


    </Container>

  )

  }
}

export default Training; 
