// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("imgs/email_logo.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("imgs/Twitter X.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("imgs/tg_logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n      --footer_email_logo: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n      --footer_twitter_logo: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n      --footer_tg_logo: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n.email_logo {\n   background-image: var(--footer_email_logo);\n   background-repeat:  no-repeat, no-repeat;\n   background-size: 90% 90%;\n}\n\n.twitter_logo {\n   background-image: var(--footer_twitter_logo);\n   background-repeat:  no-repeat, no-repeat;\n   background-size: 90% 90%;\n}\n\n.tg_logo {\n   background-image: var(--footer_tg_logo);\n   background-repeat:  no-repeat, no-repeat;\n   background-size: 90% 90%;\n}\n", "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA;MACM,4DAA6C;MAC7C,8DAA8C;MAC9C,yDAAuC;AAC7C;;AAEA;GACG,0CAA0C;GAC1C,wCAAwC;GACxC,wBAAwB;AAC3B;;AAEA;GACG,4CAA4C;GAC5C,wCAAwC;GACxC,wBAAwB;AAC3B;;AAEA;GACG,uCAAuC;GACvC,wCAAwC;GACxC,wBAAwB;AAC3B","sourcesContent":[":root {\n      --footer_email_logo: url(imgs/email_logo.svg);\n      --footer_twitter_logo: url(imgs/Twitter X.svg);\n      --footer_tg_logo: url(imgs/tg_logo.svg);\n}\n\n.email_logo {\n   background-image: var(--footer_email_logo);\n   background-repeat:  no-repeat, no-repeat;\n   background-size: 90% 90%;\n}\n\n.twitter_logo {\n   background-image: var(--footer_twitter_logo);\n   background-repeat:  no-repeat, no-repeat;\n   background-size: 90% 90%;\n}\n\n.tg_logo {\n   background-image: var(--footer_tg_logo);\n   background-repeat:  no-repeat, no-repeat;\n   background-size: 90% 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
