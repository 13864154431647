import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Banner.css';
import React, { Component } from "react";

class Banner extends Component {

  constructor(props){
     super(props)
     this.state = {screen_size: "none",CustClass1:"d-flex",CustClass0:"d-flex justify-content-center pt-2"}

  }

   componentDidMount(){
      window.addEventListener("resize",this.resize.bind(this));
      this.resize();
   }


   resize(){
      this.setState({width: window.innerWidth, height: window.innerHeight});

      if (window.innerWidth < 576){
          //console.log("X-Small")

      } else if (window.innerWidth > 576 && window.innerWidth < 768){
          //console.log("Small")
          this.setState({screen_size: "Small"})

      } else if (window.innerWidth > 768 && window.innerWidth < 992){
          //console.log("Medium")
          this.setState({screen_size: "Medium", CustClass1: "d-flex justify-content-center"})

      } else if (window.innerWidth > 992 && window.innerWidth < 1200){
          //console.log("Large")
          this.setState({screen_size: "Large", CustClass1: "d-flex", CustClass0: "d-flex justify-content-center pt-2"})

      } else if (window.innerWidth > 1200 && window.innerWidth < 1400){
          //console.log("X-Large")
          this.setState({screen_size: "X-Large"})

      } else if (window.innerWidth > 1400){
          //console.log("XX-Large")
          this.setState({screen_size: "XX-Large"})
      }
   }

  render(){

     var { Fixed: Fixed } = this.props

     // Here we add the Fixed variable to the className.  This allows us to call the Banner component and set it to fixed-top
     var CustClassName = "row_top align-items-top" + Fixed

     return (
          <Row className={CustClassName} style={{height: "auto"}}>
             <Col className={this.state.CustClass0} style={{background: "none"}}> 
                <a href="/">
                   <div className="logo" style={{height: "70px",width: "70px"}}>
                   </div>
                </a>
                <div className="pt-4 pb-4" style={{fontFamily: "'Lato', sans-serif", fontWeight: '400', fontSize: '26px'}}>
                   <a href="/" className="text-decoration-none text-nowrap" style={{ color: "white"}}>
                      CyberSecuritySkills
                   </a>
                </div> 
             </Col>
             <Col className={this.state.CustClass1} style={{background: "none"}}> 
             <Navbar collapseOnSelect expand="md" className="navbar-custom d-flex justify-content-center">
                <Container>
                 <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color:"#06064400",margin: "0 auto"}}/>
                 <Navbar.Collapse id="basic-navbar-navr">
                   <Nav className="md-auto">
                     <Nav.Link className="d-flex justify-content-center" href="/" style={{color:"white",fontFamily: "'Lato', sans-serif",fontSize:"20px"}}>Home</Nav.Link>
                     <Nav.Link className="d-flex justify-content-center" href="/training" style={{color:"white",fontFamily: "'Lato', sans-serif",fontSize:"20px"}}>Courses</Nav.Link>
                     <Nav.Link className="d-flex justify-content-center" href="/assessment" style={{color:"white",fontFamily: "'Lato', sans-serif",fontSize:"20px"}}>Assessment</Nav.Link>
                     <Nav.Link className="d-flex justify-content-center" href="/consulting" style={{color:"white",fontFamily: "'Lato', sans-serif",fontSize:"20px"}}>Consulting</Nav.Link>
                   </Nav>
                 </Navbar.Collapse>
               </Container>
             </Navbar> 
             </Col>
          </Row>
      );

   }
}

export default Banner;
